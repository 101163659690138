/* Coin.module.css */
.parent {
    perspective: 1000px;
    /* Adjust the value as needed */
}

.coin {
    width: 30vh;
    height: 30vh;
    transform-style: preserve-3d;
    transform: translateZ(0);
    /* Ensure proper 3D rendering */
}

.face {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    backface-visibility: hidden;
    background-size: cover;
    background-position: center;
}

.heads {
    background-image: url('../assets/heads.png');
}

.tails {
    background-image: url('../assets/tails.png');
    transform: rotateY(180deg);
}

@keyframes flipHeads {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}

@keyframes flipTails {
    from {
        transform: rotateY(180deg);
    }

    to {
        transform: rotateY(540deg);
    }
}

.flippingHeads {
    animation: flipHeads 1s infinite;
}

.flippingTails {
    animation: flipTails 1s infinite;
}

.landHeads {
    transform: rotateY(0deg);
}

.landTails {
    transform: rotateY(0deg);
}